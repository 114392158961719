<template>
  <div class="row">
    <footer class="text-center">
      <p>
        2022 © Xonder <br />
      </p>
    </footer>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  computed: {}
};
</script>
